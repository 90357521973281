body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  height: 75px;
  position: relative !important;
}

header > div.RaAppBar-toolbar {
  min-height: 75px;
}

header svg {
  width: 1.3em !important;
  height: 1.3em !important;
}

#root > div > div > div.layout {
  background-color: #5D20C0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

main {
  padding: 50px 50px 50px 5px;
}
main > .RaSidebar-docked  {
  height: fit-content;
}

.RaLayout-appFrame {
  background-color: rgba(255, 255, 255, 0.29);
  margin-top: 0 !important;
}

p.RaLabeled-label span {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

#main-content {
  padding: 25px;
  border-radius: 25px;
  overflow-y: scroll;
}

button, a {
  text-transform: none !important;
  border-radius: 10px !important;
}